import {defineStore} from 'pinia';
import axios from "axios";

let originalSetInterval = window.setInterval;
let originalSetTimeout = window.setTimeout;
let intervalTimerList = [];
let timeOutTimerList = [];


export const viewData = defineStore('viewData', {
    state: () => ({
        // 核心狀態
        isMobile: false,
        isTablet: false,
        windowWidth: 1000,
        windowHeight: 500,
        pageAreaName: '',
        pageTitle: '',
        menuTitle: '',
        mainMenuName: '',
        showDrawer: false,
        drawerName: '',
        drawerTitle: '',
        drawerWidth: 600,
        drawerParameter: {},
        currentPage: null,
        currentDrawer: null,
        userSession: null,
        shouldScrollToTop:false,
        isChecked: false,
        landingPage: "/project/list",

        // 僅適用於此 app 的狀態
        debug: location.hostname.indexOf('localhost') >= 0,
        manageAuth:location.hostname.indexOf('localhost') >= 0,
        showSubMenu: true,
        headerShow: false,
        scrollTop:0,
        lastScrollTop: 0,
        accumulateScroll: 0, // 新增用來追蹤累積滾動距離的狀態
        navPlaceholder: false,
        projectStatus:'open',

        currentField:{},
        anyMaskShow:false,
        userIsLogin:false,
        needToTopIcon:false,
        userData:{},
        userList:[],
        userFieldOverview:{},
        fieldOverviewList:[],
        userDealsRecordList:[],
        generationList:[],

        fieldKindList:[
            {value:"PV",label:"光電"},
            {value:"BG",label:"沼氣"},
            {value:"HP",label:"熱泵"},
            {value:"ES",label:"儲能"},
            {value:"OT",label:"其他"},
        ],

        buyCurrent:null,
        buyCurrentCategory:null,
        categories: [
            {
                size: 'part',
                status: ['open', 'close'],
                icon: require('@/assets/svg/icon/buypart.svg'),
                label: '小額購買',
            },
            {
                size: 'all',
                status: ['open', 'close'],
                icon: require('@/assets/svg/icon/buyall.svg'),
                label: '全場購買',
            }
        ],
    }),
    getters: {
        ssoUrl: (state) => state.userSession ? state.userSession.ssoUrl : '',
        isLogin: (state) => state.userSession ? state.userSession.isLogin : false,
        roleList: (state) => state.userSession ? state.userSession.roleList : [],
        currentUser: (state) => state.userSession ? state.userSession.currentUser : {},
    },
    actions: {
        attachUserSession(userSession) {
            this.userSession = userSession;
        },
        hasRole(role) {
            return this.userSession ? this.userSession.hasRole(role) : false;
        },
        checkPermission(permissions) {
            return this.userSession ? this.userSession.checkPermission(permissions) : false;
        },
        handleUserLogout() {
            if (!this.isLogin) {
                this.eventBus.emit('userLogout');
                location.href = this.landingPage
            }
        },
        handleUserInit(data){
            this.isChecked = true;
            this.eventBus.emit('userInit', data);
        },
        beforePageChange(to, from) {
            this.clearPageInterval();
            this.clearPageTimeout();
            console.log('Before page change:', to.path, from.path)
            if (to.path !== from.path) {
                this.shouldScrollToTop = true;
            }
            if (to.meta && to.meta.permissions) {
                if (!this.checkPermission(to.meta.permissions)) {
                    if (this.isChecked){
                        return {name: 'error_AccessDenied'};
                    }else{
                        return {name: 'error_UserNotReady'};
                    }
                }
            }
        },
        afterPageChange() {
            let path = location.pathname;
            let pageName = path.split('/').join('_').substring(1);
            if (pageName.length === 1) pageName = 'components_' + pageName;
            console.log(pageName)
            this.pageAreaName = pageName;
            setTimeout(() => {
                this.mainMenuName = pageName.split("_")[0];
            }, 30);
            if (this.shouldScrollToTop) {
                //跳頁時回到最頂端
                document.documentElement.scrollTop = 0
            }
        },
        clearPageInterval() {
            intervalTimerList.forEach(i => {
                clearInterval(i);
            });
            intervalTimerList = [];
        },
        clearPageTimeout() {
            timeOutTimerList.forEach(i => {
                clearTimeout(i);
            });
            timeOutTimerList = [];
        },
        handleWindowResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            try {
                this.drawerWidth = window.innerWidth * 0.8;
                if (window.innerWidth >= 969) this.drawerWidth = window.innerWidth * 0.6;
                if (window.innerWidth >= 1500) this.drawerWidth = window.innerWidth * 0.4;
            } catch(err) {
                console.log(err);
            }
            const mobileWidthThreshold = 768;
            if (this.windowWidth <= mobileWidthThreshold) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
        setPageInterval(callback, ms, keep = false) {
            let i = originalSetInterval(callback, ms);
            if (!keep) intervalTimerList.push(i);
        },
        setPageTimeout(callback, ms, keep = false) {
            let i = originalSetTimeout(callback, ms);
            if (!keep) timeOutTimerList.push(i);
            return i;
        },
        openDrawer(drawerName, drawerTitle, drawerParameter) {
            drawerTitle = drawerTitle || '';
            drawerParameter = drawerParameter || {};
            this.showDrawer = true;
            this.drawerTitle = drawerTitle;
            this.drawerParameter = drawerParameter;
            setTimeout(() => {
                this.drawerName = drawerName;
            }, 330);
        },

        // 僅適用於此 app 的方法
        handleScrollEvent(){
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const directionUp = scrollTop < this.lastScrollTop; // 判斷當前是否向上滾動
            const scrollDiff = Math.abs(scrollTop - this.lastScrollTop); // 計算這次和上次滾動的差異

            if (directionUp) {
                // 如果是向上滾動，累加滾動差異, 並決定是否顯示標頭
                this.accumulateScroll += scrollDiff;
                if (this.accumulateScroll > 220) { // 當累積滾動超過 220px
                    this.headerShow = true;
                    this.navPlaceholder = scrollTop > 45;
                    if (scrollTop < 15) this.headerShow = false;
                }
            } else {
                // 如果是向下滾動，重置累積滾動距離
                this.accumulateScroll = 0;
                this.headerShow = scrollTop > 150 ? false : this.headerShow;
                this.navPlaceholder = this.headerShow;
            }
            this.lastScrollTop = scrollTop;
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
        objectNumberPositiveCheck(obj){
            console.log(obj)
            let a=Object.keys(obj)
            for(let i=0;i<a.length;i++){
                let b=obj[a[i]]
                if(this.isNumber(b)){
                    if(b<0){
                        return false
                    }
                }
            }
            return true
        },
        async userListInit(){
            axios.get('/api/user/user_list.json').then(res=>{
                console.log(res)
                this.userList=res.data
            })
        },
        fieldOverviewInit(){
            axios.get('/api/field/overview.json').then(res=>{
                console.log(res)
                if(res.data.length>0)
                    this.userFieldOverview=res.data[0]
                this.fieldOverviewList=res.data
            })
        },
        payRecordInit(){
            axios.get('/api/deals/deals_record.json').then(res=>{
                console.log(res)
                this.userDealsRecordList=res.data
                this.userDealsRecordList.forEach(x=>{
                    x.detail_show=false
                })
            })
        },
        // async fetchQAInit(){
        //     const response = await axios.get('/api/qa/list.json');
        //     this.qaList = response.data;
        //     console.log(this.qaList)
        //     this.qaList.forEach(i => {
        //         i.isOpen = false;
        //     })
        // },
        async generationInit(){
            axios.get('/api/generation/data.json').then(res=>{
                console.log(res.data)
                this.generationList=res.data
                })
        }
    },
})

export default viewData