import './registerServiceWorker'
import {createApp, defineAsyncComponent} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import {createPinia} from 'pinia'
import {Chart} from 'chart.js';
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';

import mitt from "mitt";
import moment from "moment"
import axios from 'axios'
import VXETable from 'vxe-table'
import VueAxios from 'vue-axios'
import ViewUIPlus from 'view-ui-plus'

import App from './App.vue'
import {bankCodes} from '@/plugins/bank'
import {bankBranchCodes} from "@/plugins/bank";
import dataProxy from '@/plugins/dataProxy'
import {viewData} from '@/plugins/view'
import {pageData} from '@/operating.js'
import 'vxe-table/lib/style.css'
import 'chartjs-adapter-moment';
import 'view-ui-plus/dist/styles/viewuiplus.css'
import './assets/css/app.css';

import { rtdbPlugin } from 'vuefire';

moment.locale('zh-tw');

const routes = [];
const pinia = createPinia()
const app = createApp(App).use(CkeditorPlugin)

app.use(pinia)
app.use(rtdbPlugin)

const bus = mitt()
const vs = viewData()


function loadComponent(page, title, dir, parentRoute = null, permissions = null) {
    if (typeof page === "undefined") return false;
    dir = dir || 'page';
    let component = defineAsyncComponent(() =>
        import(`@/page/${dir}/${page}.vue`)
    );

    let path = `/${dir}/${page}`;

    const route = {
        name: `${dir}_${page}`,
        path: path,
        component: component,
        meta: {}
    };

    // 添加 permissions 到 meta
    if (permissions) {
        route.meta.permissions = permissions;
    }

    if (dir === 'manager' && page.endsWith('Edit')) {
        route.path += '/:id';
    }

    if (parentRoute) {
        if (!parentRoute.children) parentRoute.children = [];
        parentRoute.children.push(route);
    } else {
        routes.push(route);
    }
    app.component(dir + '_' + page, component);
}


// 先處理 layout，確保它們作為父路由存在
const parentRoutes = {};
pageData['layout'].forEach(layoutItem => {
    let component = defineAsyncComponent(() =>
        import(`@/page/${layoutItem.dir}/${layoutItem.page}.vue`)
    );
    const parentRoute = {
        path: `/${layoutItem.dir}/${layoutItem.page}`,
        component: component,
        children: []
    };
    routes.push(parentRoute);
    // 將這些 layout 路由添加到全局變量中，便於後續查找
    parentRoutes[layoutItem.page] = parentRoute;
});

console.log(parentRoutes);

// 處理其他路由
const processRoutes = (routes, parentKey = null, parentRoute = null) => {
    routes.forEach(route => {
        if (!route.dir) route.dir = parentKey || route.dir;

        const currentParentRoute = route.layout && parentRoutes[route.layout]
            ? parentRoutes[route.layout]
            : parentRoute;

        // 加載當前路由
        loadComponent(route.page, route.title, route.dir, currentParentRoute, route.permissions);

        // 如果有子路由，遞歸處理
        if (route.items && route.items.length) {
            processRoutes(route.items, route.dir, currentParentRoute);
        }
    });
};

// 處理主路由
Object.keys(pageData)
    .filter(key => key !== 'layout')
    .forEach(key => processRoutes(pageData[key], key));

const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach(async (to, from) => {
    return vs.beforePageChange(to, from)
})
router.afterEach((to, from, failure) => {
    if (!failure) vs.afterPageChange()
})


app
    .use(VueAxios, axios)
    .use(ViewUIPlus)
    .use(router)
    .use(dataProxy)
    .use(VXETable)

vs.eventBus = bus
app.config.globalProperties.Chart = Chart
app.config.globalProperties.$moment = moment
app.config.globalProperties.pageData = pageData
app.config.globalProperties.bankCodes = bankCodes
app.config.globalProperties.bankBranchCodes = bankBranchCodes
app.config.globalProperties.view = vs
app.config.globalProperties.viewStore = vs
app.config.globalProperties.eventBus = bus
app.mount('#app')