<template>
    <div>
        <div class="loginMask" @click="maskClick"></div>
        <div id="loginDiv" class="loginDiv">
            <div class="loginContent">
                <div class="leftSide">
                    <img :src="imgUrl">
                </div>
                <div class="rightSide">
                    <div class="platformLabel">
                        <img
                            src="https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/logo-2021-c.png">
                        {{ platformName }}
                    </div>
                    <div class="thirdPartLogin">
                        快速登入
                        <img class="socialIcon" :src="imgGoogleUrl" @click="signInWithGoogle">
                        <img class="socialIcon" :src="imgLineUrl" @click="signInWithLine">
                        <img class="socialIcon" :src="imgFacebookUrl" @click="signInWithFacebook">
                    </div>
                    <div class="loginLabel">{{ isLogin ? '會員登入' : '註冊新會員' }}</div>
                    <div class="inputGroup">
                        <input v-model="email" placeholder="請輸入帳號"/>
                    </div>
                    <div class="inputGroup passwordGroup">
                        <input v-model="password" :type="showPassword ? 'text' : 'password'" placeholder="請輸入密碼"/>
                        <div id="passwordLeak" class="passwordToggle fas"
                             :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                             @click="eyeClick"></div>
                    </div>
                    <div class="forgetPassword">
                        <a href="#" class="forgetLink">忘記密碼</a>
                    </div>
                    <div class="buttons">
                        <button class="registerButton" @click="toggleMode">{{
                                isLogin ? '點我註冊' : '返回登入'
                            }}
                        </button>
                        <button class="loginButton" @click="handleSubmit">{{ isLogin ? '登入' : '註冊' }}</button>
                    </div>
                    <div class="moreInformation">登入即表示您同意牧陽能控平台之帳戶條款和條件並接受隱私權政策。</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue';
import {userService} from '@/services/userService';

export default {
    name: 'LoginComponent',
    props: {
        platformName: {
            type: String,
            default: '牧陽能控平台'
        }
    },
    methods:{
      async signInWithGoogle(){
        try {
            let account = await userService.loginWithGoogle();
            console.log(account)
          if(account !== undefined && account !== null)
            this.loginSuccess(account)
        } catch (error) {
            console.error('Google sign-in error:', error);
            alert(error.message);
        }
      },
      async signInWithFacebook(){
        try {
            let account = await userService.loginWithFacebook();
            console.log(account)
          if(account !== undefined && account !== null)
            this.loginSuccess(account)
        } catch (error) {
            console.error('Google sign-in error:', error);
            alert(error.message);
        }
      },
      async signInWithLine(){
        try {
            let account = await userService.loginWithLine();
            console.log(account)
          if(account !== undefined && account !== null)
            this.loginSuccess(account)
        } catch (error) {
            console.error('Google sign-in error:', error);
            alert(error.message);
        }
      },
      loginSuccess(user){
        console.log(user)
        this.$emit('loginSuccess', user);
      },
      loginError(){

      },
      maskClick(){
        this.$emit('maskClick');
      },
    },
    setup() {
        const isLogin = ref(true);
        const email = ref('');
        const password = ref('');
        const showPassword = ref(false);

        const imgGSUrl = ref('https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/logo-2024-tb.png');
        const imgGoogleUrl = ref(require('@/plugins/gs-plugins/svg/Icon_google.svg'));
        const imgLineUrl = ref(require('@/plugins/gs-plugins/svg/Icon_line.svg'));
        const imgFacebookUrl = ref(require('@/plugins/gs-plugins/svg/Icon_facebook.svg'));
        const imgUrl = ref(require('@/plugins/gs-plugins/svg/loginSide.svg'));

        const handleSubmit = async () => {
            try {
                let user;
                if (isLogin.value) {
                    user = await userService.loginUser(email.value, password.value);
                } else {
                    user = await userService.registerUser(email.value, password.value);
                }
                console.log('User:', user);
            } catch (error) {
                console.error('Authentication error:', error);
                alert(error.message);
            }
        };

        const toggleMode = () => {
            isLogin.value = !isLogin.value;
        };

        const eyeClick = () => {
            showPassword.value = !showPassword.value;
        };

        const ssoLogin = () => {
            // 實現 SSO 登錄邏輯
        };

        return {
            isLogin,
            email,
            password,
            showPassword,
            imgGSUrl,
            imgGoogleUrl,
            imgLineUrl,
            imgFacebookUrl,
            imgUrl,
            handleSubmit,
            toggleMode,
            eyeClick,
            ssoLogin
        };
    }
}
</script>

<style lang="stylus" scoped>
.loginMask
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 98
    background: hsla(0, 0%, 40%, .8)

.loginDiv
    font-family: Orbitron, Noto Sans TC, sans-serif
    height: 600px
    width: 80%
    max-width: 870px
    position: fixed
    left: calc(50% - 40%)
    top: calc(50% - 300px)
    z-index: 99
    overflow: hidden
    @media (min-width: 1500px)
        left: calc(50% - 435px)
    @media (max-width: 900px)
        width: 100%
        left: 0

.loginContent
    display: grid
    grid-template-columns: 2fr 6fr
    height: 100%

.leftSide
    background: #a8daff
    height: 100%
    position: relative
    pointer-events: none

    img
        pointer-events: none
        position: absolute
        bottom: -50px
        left: 0
        height: 620px


.rightSide
    background: #fff
    padding: 30px 50px 40px 40px
    text-align: left
    @media (max-width: 1100px)
        z-index: 99

.platformLabel
    color: #7B7B7B
    font-size: 22px
    margin-bottom: 20px
    letter-spacing: 1px
    height: 36px
    line-height: 28px
    font-weight 900

    img
        width: 32px
        height: 32px
        float: left
        margin: -2px 10px 0 0

.thirdPartLogin
    color: #a7a7a7
    font-size: 20px
    line-height: 40px
    margin-bottom: 50px
    font-weight: 600
    letter-spacing: 1px

    .socialIcon
        height: 38px
        width: 38px
        cursor: pointer
        float: right
        margin-left: 20px

        &.grayscale
            filter: grayscale(100%)
            cursor: default
            @media (max-width: 580px)
                display none

.loginLabel
    color: #787878
    font-size: 24px
    margin-bottom: 18px
    font-weight: 700
    letter-spacing: 3px

.inputGroup
    margin-bottom: 16px
    position: relative

    input
        width: 100%
        border: none
        border-bottom: solid 1px #d2d2d2
        border-radius: 0
        line-height: 34px
        outline: none
        padding: 8px 0

        &::placeholder
            color: #bdbdbd

.passwordGroup
    .passwordToggle
        position: absolute
        right: 0
        top: 50%
        transform: translateY(-50%)
        cursor: pointer
        color: #20496d

.forgetPassword
    text-align: right
    margin-bottom: 40px

    a
        color: #0f4c81
        font-size: 16px
        line-height: 24px
        text-decoration: underline

        &:hover
            color: #96C800

    .ssoLogin
        margin-right: 25px

.buttons
    margin-bottom: 78px
    height: 55px

    @media (max-width: 900px)
        margin-bottom: 18px

    button
        width: calc(50% - 20px)
        max-width 280px
        height: 52px
        float: left
        font-size: 22px
        line-height: 28px
        text-align: center
        letter-spacing: 8px
        border: #96C800 solid 2px
        cursor: pointer
        font-weight: 500
        @media (max-width: 900px)
            font-size: 16px

        &:disabled
            background: #f2f2f2
            color: #bfbfbf
            cursor: not-allowed

            &:hover
                background: #f2f2f2
                color: #bfbfbf
                cursor: not-allowed


    .registerButton
        margin-right: 40px
        background: #ffffff
        color: #96C800

        @media (max-width: 900px)
            margin-right: 16px

        &:hover
            background: #efefef
            color: #81ab04

    .loginButton
        color: #ffffff
        background: #96C800

        &:hover
            background: #81ab04

.moreInformation
    clear: both
    text-align: center
    color: #7b7b7b
    font-size: 14px
</style>