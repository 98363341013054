export {pageData};

let pageData = {
    'layout': [
        { dir: 'layout', title: '即時', page: 'fieldLayout'},
        { dir: 'layout', title: '調度', page: 'userLayout'},
        { dir: 'manager', title: '管理', page: 'managerLayout'},
        // { dir: 'layout', title: '調度', page: 'dispatchLayout'},
        // { dir: 'layout', title: '說明', page: 'helpLayout'},
    ],
    'login':[
        {
            title: '登入',
            name: 'user',
            items: [
                {dir: 'user', title: '登入頁面', page: 'Login'},
                {dir: 'user', title: '通知', page: 'userNotify'},
                {dir: 'user', layout: 'userLayout', title: '會員頁面', page: 'memberPage' },
                {dir: 'user', layout: 'userLayout', title: '登入頁面', page: 'account' },
                {dir: 'user', layout: 'userLayout', title: '交易記錄', page: 'dealsRecord' },
                {dir: 'user', layout: 'userLayout', title: '收益概覽', page: 'earningOverview' },
                {dir: 'user', layout: 'userLayout', title: '發電狀況', page: 'generationStatus' },
                {dir: 'user', layout: 'userLayout', title: '投標狀況', page: 'bidStatus' },
                {dir: 'user', layout: 'userLayout', title: '交易狀況', page: 'dealStatus' },
                {dir: 'user', layout: 'userLayout', title: '修改密碼', page: 'passwordModify' },
                {dir: 'user', layout: 'userLayout', title: '支付記錄', page: 'payRecord' },
                {dir: 'user', layout: 'userLayout', title: '預測總覽', page: 'predictSum' },
                {dir: 'user', layout: 'userLayout', title: '案場刊登', page: 'fieldPost' },

                {dir: 'user', layout: 'userLayout', title: '案場投資需求', page: 'buyProjectPost' },
                {dir: 'user', layout: 'userLayout', title: '收款帳戶', page: 'receivingAccount' },
                {dir: 'user', layout: 'userLayout', title: '銷售狀況', page: 'saleStatus' },
                {dir: 'user', layout: 'userLayout', title: '通知', page: 'notification' },
            ]
        }
    ],
    'nav': [
        {
            title: '碳權與憑證',
            name: 'carbon',
            items:[
                {dir: 'carbon', title: '地圖', page: 'MapBox'},
            ]
        },
        {
            title: '綠能專案',
            name: 'project',
            items: [
                {dir: 'project', title: '綠能專案', page: 'list'},
                {dir: 'project', title: '綠能專案', page: 'detail'},
                {dir: 'project', title: '認購頁面', page: 'payPage'},
            ],
            visible:true,
        },
        {
            title: '案場仲介',
            name: 'field',
            items: [
                {dir: 'field', layout: 'fieldLayout', title: '案場仲介', page: 'allFieldPage'},
                {dir: 'field', layout: 'fieldLayout', title: '交易資訊', page: 'dealingsInformation'},
                {dir: 'field', layout: 'fieldLayout', title: '交易資訊', page: 'sellerPage'},
                {dir: 'field', layout: 'fieldLayout', title: '交易資訊', page: 'auditProgress'},
                {dir: 'field', layout: 'fieldLayout', title: '交易資訊', page: 'buyerPage'},

            ],
            visible:true,
        },
        {
            title: 'Q&A',
            name: 'qa',
            items: [
                {dir: 'qa', title: 'Q&A', page: 'QArea'},
            ],
            visible:true,
        },
        {
            title: '管理',
            name: 'manager', 
            permissions: ['administrator', 'com_manager'],
            items: [
                { dir: 'manager', layout: 'managerLayout', title: '案場管理', page: 'fieldList', permissions: ['administrator', 'com_manager']},
                { dir: 'manager', layout: 'managerLayout', title: '編輯案場', page: 'fieldEdit', permissions: ['administrator']},
                { dir: 'manager', layout: 'managerLayout', title: '問答列表', page: 'qaList', permissions: ['administrator']},
                { dir: 'manager', layout: 'managerLayout', title: '管理場地', page: 'manageField', permissions: ['administrator']},
                { dir: 'manager', layout: 'managerLayout', title: '交易紀錄', page: 'manageDealsRecord', permissions: ['administrator']},
                { dir: 'manager', layout: 'managerLayout', title: '管理用戶', page: 'manageUser', permissions: ['administrator']},
                { dir: 'manager', layout: 'managerLayout', title: '編輯問答', page: 'qaEdit', permissions: ['administrator']},
                { dir: 'manager', layout: 'managerLayout', title: '管理概覽', page: 'manageOverview', permissions: ['administrator']},
                { dir: 'manager', layout: 'managerLayout', title: '管理發電', page: 'manageGeneration', permissions: ['administrator']},
            ],
            visible: true,
        },
    ],
    'drawer': [
    ],
    'page':[
        {dir: 'error', title: '拒絕存取',page: 'AccessDenied',},
        {dir: 'error', title: '尚未完成',page: 'UserNotReady',},
        {dir: 'test', title:'firebase測試',page:'testFirebase'},
        {dir: 'user', title: '編輯表格', page:'editTable'}
    ],
}
