import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getDatabase} from "firebase/database";
import {getAuth} from 'firebase/auth';
import {initializeAppCheck, ReCaptchaV3Provider, CustomProvider} from 'firebase/app-check';

const firebaseConfig = {
    apiKey: "AIzaSyDrqvw0eGaj7Fd813vWoPc1BzwBkb8OXZM",
    authDomain: "greenshepherdcomtw.firebaseapp.com",
    databaseURL: "https://gsmarket.firebaseio.com",
    projectId: "greenshepherdcomtw",
    storageBucket: "greenshepherdcomtw.appspot.com",
    messagingSenderId: "219264788569",
    appId: "1:219264788569:web:0e693dac755ba34e56a948"
};

const app = initializeApp(firebaseConfig);

const isDevelopment = process.env.NODE_ENV === 'development';

let appCheck;

if (isDevelopment) {
    // 使用自定义提供程序进行本地开发
    const debugToken = 'B73A94A3-577E-48EF-811F-959083CECEBF'; // 替换为你的调试令牌
    appCheck = initializeAppCheck(app, {
        provider: new CustomProvider({
            getToken: () => Promise.resolve({
                token: debugToken,
                expireTimeMillis: Date.now() + 60 * 60 * 1000, // 1 hour
            }),
        }),
        isTokenAutoRefreshEnabled: true
    });
} else {
    // 生产环境使用 ReCaptcha V3
    appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider('YOUR_RECAPTCHA_SITE_KEY'),
        isTokenAutoRefreshEnabled: true
    });
}

const auth = getAuth(app);
const db = getFirestore(app, "db-om");
const realtimeDb = getDatabase(app);

export {app, db, realtimeDb, auth, appCheck};