<template>
    <div id="anyMask" class="anyMask" @click="view.anyMaskShow=!view.anyMaskShow" :class="{'active':view.anyMaskShow}" v-show="view.anyMaskShow"></div>
    <div id="layout" :class="{'isMobile': view.isMobile,'headerHidden':!view.navPlaceholder}">
        <div id="nav" :class="{fixed: view.headerShow}">
            <div class="container" style="position: relative;">
                <span class="logo boldLabel" @click.alt.prevent="view.debug = !view.debug">GS交易平台</span>
                <div class="menus" style="float: left;">
                    <div v-for="k in navData" class="mainMenuItem" @click="mainMenuSelect(k)"
                         :class="{active: k.name === view.mainMenuName,noShow:!k.visible}" :key="k.name">
                        {{ k.title }}
                    </div>
                </div>
                <Apps :platformName="'GS交易平台'" :view="view" v-model:showApps="showApps" @update:userLogout="view.handleUserLogout"></Apps>
            </div>
        </div>

        <div id="content" ref="viewContent" :class="{'hideNav': view.navPlaceholder}">
            <router-view v-if="isRouterAlive"></router-view>
        </div>
        <div id="footer" v-show="showFooter">
            <div class="container" style="pointer-events: none; height: 450px; position: relative;">
                <div class="pageBottomIcon" id="pageBottomIcon"></div>
            </div>
            <div class="bar">
                Copyright © 2024 GreenShepherd All Right Reserved.
            </div>
        </div>
    </div>
    <BackTop :height="200" :bottom="200"><div id="toTopButton"></div></BackTop>
</template>

<script>
import Swal from "sweetalert2"
import {Apps} from '@/plugins/gs-plugins'
import axios from "axios";
import {globalStateData} from "@/plugins/globalState";

export default {
    name: 'App',
    components: {
        Apps
    },
    setup() {
      const gsData = globalStateData();
      return { gsData };
    },
    computed: {
        navData() {
            let nav = this.pageData['nav'];
            if (!this.gsData.isLogin) {
                return nav.filter(item => !item.permissions);
            }

            return nav.map(item => {
                if (item.permissions) {
                    // 檢查用戶是否有權限查看此菜單項
                    const hasPermission = item.permissions.some(permission =>
                        this.view.hasRole(permission)
                    );

                    if (!hasPermission) {
                        return null; // 如果沒有權限,返回 null(之後會被過濾掉)
                    }

                    // 如果有子菜單項,進行過濾
                    if (item.items) {
                        item.items = item.items.filter(subItem => {
                            return !subItem.permissions || subItem.permissions.some(permission =>
                                this.view.hasRole(permission)
                            );
                        });

                        // 如果過濾後沒有子菜單項,也返回 null
                        if (item.items.length === 0) {
                            return null;
                        }
                    }
                }
                return item;
            }).filter(Boolean);
        },
        showFooter() {
            return !(this.$route.path.startsWith('/user') || this.$route.path.startsWith('/carbon'))
        },
    },
    data() {
        return {
            isRouterAlive: true,
            showApps: false,
            message: "",
            showLogin: false,
            toTopButtonIsActive: false,
            initPath: '',
            messageList: [
                {
                    title: '您有一筆新的訂單',
                    icon: 'md-notifications',
                    iconColor: 'primary',
                    time: '2024-01-01 12:00',
                    read: true,
                },
                {
                    title: '您有一筆新的訂單',
                    icon: 'md-notifications',
                    iconColor: 'primary',
                    time: '2024-01-01 12:00',
                    read: false,
                },
                {
                    title: '您有一筆新的訂單',
                    icon: 'md-notifications',
                    iconColor: 'primary',
                    time: '2024-01-01 12:00',
                    read: false,
                },
            ],
        }
    },
    created() {
    },
    unmounted() {
        window.removeEventListener('scroll', this.view.handleScrollEvent);
        window.removeEventListener('resize', this.view.handleWindowResize);
    },
    mounted() {
        this.initPath = location.pathname
        this.eventBus.on('userInit', this.userInit);
        this.view.handleScrollEvent();
        this.view.handleWindowResize();
        window.addEventListener('scroll', this.view.handleScrollEvent);
        window.addEventListener('resize', this.view.handleWindowResize);


        window["alert"] = function (msg, timeout, allowOutsideClick) {
            if (typeof allowOutsideClick === "undefined") allowOutsideClick = true;
            Swal.close()
            setTimeout(function () {
                if (timeout !== undefined) {
                    Swal.fire({
                        title: "",
                        html: msg,
                        timer: timeout,
                        showConfirmButton: false,
                        allowOutsideClick: allowOutsideClick,
                    });
                } else {
                    if (msg) {
                        Swal.fire({
                            title: msg,
                        });
                    }
                }
            }, 100)
        }
    },
    methods: {
        userIconClick() {
            if (this.view.isLogin) {
                this.$router.push('/user/account')
            } else {
                this.showLogin = !this.showLogin
            }
        },
        handleMaskClick() {
            this.showLogin = false
        },
        handleLoginSuccess() {
            console.log('loginSuccess')
            this.showLogin = false
            this.$router.push('/user/memberPage')
        },
        async userInit(data) {
            console.log(data)
            try{
              if (this.gsData.isLogin) {
                  if (location.pathname === '' || location.pathname === '/') {
                      this.$router.push(this.view.landingPage)
                  } else {
                      this.$router.push(this.initPath)
                  }
                  //預計刪除部分
                  axios.get('/api/user/user.json').then(res=>{
                      console.log(res)
                      this.userData=res.data
                      this.view.fieldOverviewInit()
                      this.view.payRecordInit()
                      // this.fetchQAInit()
                      this.view.generationInit()
                  })
              }else{
                  this.$router.push(this.view.landingPage)
              }
            }catch(e){
              console.error(e.toString())
            }

        },
        mainMenuSelect(k) {
            if (k.name === 'user') {
                this.showLogin = !this.showLogin
                return
            }
            let i = k.items[0]
            console.log(i)
            this.$router.push(`/${i.dir}/${i.page}`)
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true
            });
            return false
        },

    },
}
</script>

<style lang="stylus">

.user-buttons
    margin: 42px 12px 0 12px;
    width: 160px;
    height: 50px;
    a
        display: inline-block;
        width: 50px;
        height: 40px;
        border-radius: 50%;
        text-align center;
        padding-top 3px;
        img
            width: 36px;
            height: 36px;
            cursor: pointer;


.pageBottomIcon
    background-image: url(@/assets/svg/Icon_Bottom.svg);
    background-repeat: no-repeat;
    position:absolute;
    right:-70px;
    bottom:0;
    width: 527px;
    height: 485px;
    z-index: 1;

</style>