import { db, auth } from '@/firebaseConfig';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider,
    linkWithCredential,
    fetchSignInMethodsForEmail,
    EmailAuthProvider,
    linkWithPopup
} from 'firebase/auth';
import {doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs, deleteDoc} from "firebase/firestore";

const CACHE_DURATION = 5 * 60 * 1000; // 緩存有效期為 5 分鐘

export const userService = {
    // 添加緩存對象
    cache: {
    },

    // 緩存清理方法
    clearCache() {
        this.cache = {
        };
    },

    isCacheValid(cacheEntry) {
        return cacheEntry && (Date.now() - cacheEntry.timestamp < CACHE_DURATION);
    },

    // 註冊新用戶
    async registerUser(email, password) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await this.createOrUpdateUserProfile(userCredential.user);
        return userCredential.user;
    },

    // 用戶登入
    async loginUser(email, password) {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        await this.createOrUpdateUserProfile(userCredential.user);
        return userCredential.user;
    },

    // 使用 Google 登入
    async loginWithGoogle(joinToCurrentUser = false) {
        return this.loginWithProvider('google', joinToCurrentUser);
    },

    // 使用 LINE 登入
    async   loginWithLine(joinToCurrentUser = false) {
        return this.loginWithProvider('oidc.line', joinToCurrentUser);
    },

    // 使用 Facebook 登入
    async loginWithFacebook(joinToCurrentUser = false) {
        return this.loginWithProvider('facebook', joinToCurrentUser);
    },

    async promptUserForPassword(email) {
        // 这里应该实现一个方法来获取用户密码
        // 可能需要弹出一个输入框或使用其他UI交互方式
        // 这里只是一个示例，实际实现可能需要根据你的UI框架来调整
        return prompt(`请输入 ${email} 的密码以链接账户：`);
    },

    async loginWithProvider(providerName, joinToCurrentUser = false) {
        let provider;
        switch (providerName) {
            case 'google':
                provider = new GoogleAuthProvider();
                break;
            case 'facebook':
                provider = new FacebookAuthProvider();
                break;
            case 'oidc.line':
                provider = new OAuthProvider('oidc.line');
                break;
            default:
                throw new Error('Unsupported provider');
        }

        try {
            if (joinToCurrentUser) {
                const currentUser = auth.currentUser;
                if (!currentUser) {
                    throw new Error('No current user to join to');
                }
                const result = await linkWithPopup(currentUser, provider);
                await this.mergeUserProfiles(currentUser, result.user);
                return result.user;
            } else {
                const result = await signInWithPopup(auth, provider);
                await this.createOrUpdateUserProfile(result.user);
                return result.user;
            }
        } catch (error) {
            if (error.code === 'auth/account-exists-with-different-credential') {
                // 处理已存在账户的情况
                throw new Error(`请使用登录`);
                // return await this.handleExistingAccountError(error, provider);
            } else {
                console.error('Login error:', error);
                throw error;
            }
        }
    },

    async handleExistingAccountError(error) {
        const email = error.customData.email;
        const pendingCred = error.credential;
        const methods = await fetchSignInMethodsForEmail(auth, email);

        if (methods.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
            const password = await this.promptUserForPassword(email);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            await linkWithCredential(userCredential.user, pendingCred);
            await this.createOrUpdateUserProfile(userCredential.user);
            return userCredential.user;
        } else {
            throw new Error(`请使用 ${methods[0]} 登录`);
        }
    },

    cleanProviderName(providerName) {
        if (providerName === 'google.com') {
            return 'google';
        } else if (providerName === 'facebook.com') {
            return 'facebook';
        } else if (providerName === 'oidc.line') {
            return 'line';
        } else {
            return providerName;
        }
    },

    async findUserDoc(user, providerName = null){
        const { uid, email} = user;
        let userDoc;
        if (email) {
            userDoc = await this.findUserWithWhere('email', email);
        } else {
            userDoc = await this.findUserWithWhere(providerName + 'Id', uid);
        }
        return userDoc;
    },

    async createOrUpdateUserProfile(user) {
        const { uid, email, displayName, photoURL } = user;
        let providerName = this.cleanProviderName(user.providerData[0].providerId);
        let userDoc = await this.findUserDoc(user, providerName);
        let providerIdList = {};
        user.providerData.forEach(p => {
            providerIdList[`${this.cleanProviderName(p.providerId)}Id`] = p.uid;
        })

        if (userDoc) {
            // 更新现有用户信息
            await updateDoc(doc(db, 'users', userDoc.id), {
                displayName: displayName || userDoc.displayName,
                photoURL: photoURL || userDoc.photoURL,
                lastLoginAt: new Date(),
                ...providerIdList
            });
            return userDoc.id;
        } else {
            // 创建新用户
            const userData = {
                email,
                displayName: displayName || '',
                photoURL: photoURL || '',
                createdAt: new Date(),
                lastLoginAt: new Date(),
                ...providerIdList
            };
            await setDoc(doc(db, 'users', uid), userData);
            return uid;
        }
    },

    async mergeUserProfiles(currentUser, newUser) {
        const currentUserProfile = await this.getUserProfile(currentUser);
        const newUserProfile = await this.getUserProfile(newUser);
        let providerIdList = {};
        newUser.providerData.forEach(p => {
            providerIdList[`${this.cleanProviderName(p.providerId)}Id`] = p.uid;
        });
        // 合并用户数据
        const mergedProfile = {
            ...currentUserProfile,
            ...newUserProfile,
            ...providerIdList,
            email: currentUserProfile.email || newUserProfile.email,
            displayName: currentUserProfile.displayName || newUserProfile.displayName,
            photoURL: currentUserProfile.photoURL || newUserProfile.photoURL,
            lastLoginAt: new Date()
        };

        // 更新当前用户的 profile
        await this.updateUserProfile(mergedProfile);

        // 如果新用户有单独的文档，删除它
        if (newUserProfile.id !== currentUserProfile.id) {
            await deleteDoc(doc(db, 'users', newUserProfile.id));
        }

        // 清除缓存
        this.clearCache();
    },

    async findUserWithWhere(fieldName, fieldValue) {
        if (!fieldValue) return null;
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where(fieldName, '==', fieldValue));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            return { id: doc.id, ...doc.data() };
        }
        return null;
    },

    // 創建用戶檔案
    async createUserProfile(userId, userData) {
        const userRef = doc(db, 'users', userId);
        await setDoc(userRef, userData);
        return userId;
    },

    // 獲取當前用戶檔案
    async getCurrentProfile() {
        const user = auth.currentUser;
        if (user) {
            return await this.getUserProfile(user.uid);
        }
        return null;
    },

    // 獲取指定用戶檔案
    async getUserProfile(user) {
        const userId = typeof user === 'string' ? user : user.uid;
        const cacheKey = `user_${userId}`;

        // Check cache
        if (this.isCacheValid(this.cache[cacheKey])) {
            return this.cache[cacheKey].data;
        }

        let userData;
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
            userData = { id: userId, ...userDoc.data() };
        }
        if (userData) {
            this.cache[cacheKey] = { data: userData, timestamp: Date.now() };
            return userData;
        }
        return {};
    },

    // 更新用戶檔案
    async updateUserProfile(userData) {
        const { id, ...updateData } = userData;
        if (!userData.id) {
            throw new Error('更新用戶檔案需要提供用戶 ID');
        }
        const userRef = doc(db, 'users', id);
        await updateDoc(userRef, updateData);
    },
};

export default userService;